var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"profile_form",staticClass:"add-form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return (() => {}).apply(null, arguments)}}},[(!_vm.invoice)?_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.INVOICE')} (*)`,"placeholder":_vm.$t('COMMON.INVOICE')}},[_c('sales-invoice-selector',{attrs:{"salesInvoice":_vm.salesPayment.invoice.id,"filterStatus":_vm.INVOICE_STATUS_VALIDATED,"filterable":true,"showAll":false},on:{"salesInvoiceChanged":(salesInvoiceId, salesInvoice) => {
            _vm.salesPayment.invoice.id = salesInvoiceId;
            _vm.salesPayment.amount = salesInvoice.total_remaining_payment;
            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.invoice}})],1):_vm._e(),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.SOURCE')} (*)`,"placeholder":_vm.$t('COMMON.SOURCE')}},[_c('el-select',{attrs:{"name":"Source","placeholder":"Source"},on:{"change":() => {
            _vm.onFormChanged();
          }},model:{value:(_vm.salesPayment.source),callback:function ($$v) {_vm.$set(_vm.salesPayment, "source", $$v)},expression:"salesPayment.source"}},_vm._l((_vm.sourcesOption),function(label,val){return _c('el-option',{key:val,attrs:{"value":val,"label":label}})}),1)],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.source}})],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":_vm.$t('COMMON.STATUS')}},[_c('el-select',{attrs:{"name":"status"},model:{value:(_vm.salesPayment.status),callback:function ($$v) {_vm.$set(_vm.salesPayment, "status", $$v)},expression:"salesPayment.status"}},_vm._l((_vm.statusesOption),function(label,val){return _c('el-option',{key:val,attrs:{"value":val,"label":`${label ? _vm.$t('PAYMENTS.' + label) : ''}`},on:{"change":() => {
              _vm.onFormChanged();
            }}})}),1)],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.status}})],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.AMOUNT')} (*)`,"placeholder":"amount","type":"number","step":"0.0001","input-classes":"form-control-alternative"},on:{"change":() => {
          _vm.onFormChanged();
        }},model:{value:(_vm.salesPayment.amount),callback:function ($$v) {_vm.$set(_vm.salesPayment, "amount", $$v)},expression:"salesPayment.amount"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.amount}})],1),_c('div',{staticClass:"form-wrapper full"},[_c('base-input',{attrs:{"label":_vm.$t('COMMON.EXCERPT'),"placeholder":_vm.$t('COMMON.EXCERPT')}},[_c('html-editor',{on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.salesPayment.excerpt),callback:function ($$v) {_vm.$set(_vm.salesPayment, "excerpt", $$v)},expression:"salesPayment.excerpt"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.excerpt}})],1),(_vm.salesPayment.source != _vm.PAYMENT_SOURCE_MANUAL)?_c('div',{staticClass:"form-group-wrapper"},[_c('h2',{staticClass:"col-12 mb-3"},[_vm._v(_vm._s(_vm.$t("SALES_PAYMENTS.SOURCE_DETAILS")))]),_c('div',{staticClass:"form-group-wrapper-item full"},[_c('base-input',{attrs:{"label":_vm.$t('SALES_PAYMENTS.TRANSACTION_ID')},on:{"change":() => {
            _vm.onFormChanged();
          }},model:{value:(_vm.salesPayment.transaction_id),callback:function ($$v) {_vm.$set(_vm.salesPayment, "transaction_id", $$v)},expression:"salesPayment.transaction_id"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.transaction_id}})],1),_c('div',{staticClass:"form-group-wrapper-item full"},[_c('base-input',{attrs:{"label":_vm.$t('SALES_PAYMENTS.TRANSACTION_DATA')}},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.salesPayment.transaction_data),expression:"salesPayment.transaction_data"}],staticClass:"form-control",attrs:{"rows":"6"},domProps:{"value":(_vm.salesPayment.transaction_data)},on:{"change":() => {
              _vm.onFormChanged();
            },"input":function($event){if($event.target.composing)return;_vm.$set(_vm.salesPayment, "transaction_data", $event.target.value)}}})]),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.transaction_data}})],1)]):_vm._e(),_c('div',{staticClass:"submit-wrapper"},[_c('base-button',{staticClass:"btn btn-sm elite-submit",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.salesPayment.id ? _vm.$t("SALES_PAYMENTS.EDIT_SALES_PAYMENT") : _vm.$t("SALES_PAYMENTS.ADD_SALES_PAYMENT"))+" ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
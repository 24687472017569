<template>
  <div class="elite-tabs-wrapper-content">
    <div class="title-header">
      <div class="row">
        <div class="col-6">
          <sales-invoice :salesInvoice="salesPayment.invoice" />
        </div>

        <div class="col-6 text-right">
          <base-button
              type="button"
              size="sm"
              @click="validateSalesPayment(salesPayment)"
              class="elite-button add"
              v-if="salesPayment.status != 'COMPLETED'"
            >
              <span class="btn-inner--icon">
                <i class="far fa-check"></i>
              </span>
              <span class="btn-inner--text">
                {{ $t("COMMON.VALIDATE") }}
              </span>
          </base-button>
          <base-button
              type="button"
              size="sm"
              @click="cancelSalesPayment(salesPayment)"
              class="elite-button add"
            >
              <span class="btn-inner--icon">
                <i class="far fa-times"></i>
              </span>
              <span class="btn-inner--text">
                {{ $t("COMMON.CANCEL") }}
              </span>
          </base-button>
          <base-button
            class="btn-outline-danger"
            icon
            size="sm"
            v-if="
              salesPayment.status == PAYMENT_STATUS_DRAFT &&
              $currentUserCan($permissions.PERM_DELETE_SALES_PAYMENTS)
            "
            @click="deleteSalesPayment"
          >
            <span class="btn-inner--icon">
              <i class="far fa-trash-alt"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.DELETE") }}
            </span>
          </base-button>

          <base-button
            class="elite-button add"
            icon
            size="sm"
            v-if="
              salesPayment.status == PAYMENT_STATUS_DRAFT &&
              $currentUserCan($permissions.PERM_EDIT_SALES_PAYMENTS)
            "
            @click="editSalesPayment"
          >
            <span class="btn-inner--icon">
              <i class="far fa-edit"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.EDIT") }}
            </span>
          </base-button>
        </div>
      </div>
    </div>

    <div class="all-infos">
      <div class="all-infos-text">
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.DATE") }}</dt>
          <dd class="col-sm-8">
            {{ $formatDate(salesPayment.date) }}
          </dd>
        </dl>
        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_INVOICES)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.INVOICE") }}</dt>
          <dd class="col-sm-8">
            <object-link :object="salesPayment.invoice" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.LOCATION") }}</dt>
          <dd class="col-sm-8">
            <location :salesInvoice="salesPayment.invoice.id" />
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd class="col-sm-8">
            <organization :organization="salesPayment.organization" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.STATUS") }}</dt>
          <dd class="col-sm-8">
            <salesPayment-status-badge :salesPayment="salesPayment" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.AMOUNT") }}</dt>
          <dd class="col-sm-8">
            {{ $formatCurrency(salesPayment.amount) }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("SALES_PAYMENTS.SOURCE") }}</dt>
          <dd class="col-sm-8">
            {{
              $t(`SALES_PAYMENTS.SALES_PAYMENT_SOURCE_${salesPayment.source}`)
            }}
          </dd>
        </dl>

        <dl class="row" v-if="salesPayment.excerpt">
          <dt class="col-sm-4">
            {{ $t("COMMON.EXCERPT") }}
          </dt>
          <dd class="col-sm-8">
            <div v-html="salesPayment.excerpt"></div>
          </dd>
        </dl>

        <dl class="row" v-if="salesPayment.transaction_id">
          <dt class="col-sm-4">
            {{ $t("SALES_PAYMENTS.TRANSACTION_ID") }}
          </dt>
          <dd class="col-sm-8">
            {{ salesPayment.transaction_id }}
          </dd>
        </dl>

        <dl class="row" v-if="salesPayment.transaction_data">
          <dt class="col-sm-4">
            {{ $t("SALES_PAYMENTS.TRANSACTION_DATA") }}
          </dt>
          <dd class="col-sm-8">
            {{ salesPayment.transaction_data }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8" v-if="salesPayment.created_at">
            {{ $formatDate(salesPayment.created_at) }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd class="col-sm-8" v-if="salesPayment.updated_at">
            {{ $formatDate(salesPayment.updated_at) }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import SalesPaymentStatusBadge from "./SalesPaymentStatusBadge.vue";
import { PAYMENT_STATUS_DRAFT } from "@/constants/payments";
import Location from "@/components/Location";
import SalesInvoice from "@/components/SalesInvoice";

export default {
  name: "salesPayment-view-global",

  components: { SalesPaymentStatusBadge, Location, SalesInvoice },

  props: ["salesPayment"],

  data() {
    return {
      PAYMENT_STATUS_DRAFT:PAYMENT_STATUS_DRAFT,
    };
  },

  computed: {},

  created() {},

  methods: {
    validateSalesPayment() {
      this.$emit("onValidateSalesPayment", this.salesPayment);
    },
    cancelSalesPayment() {
      this.$emit("onCancelSalesPayment", this.salesPayment);
    },
    editSalesPayment() {
      this.$emit("onEditSalesPayment", this.salesPayment);
    },
    deleteSalesPayment() {
      this.$emit("onDeleteSalesPayment", this.salesPayment);
    },
  },

  mounted() {},

  watch: {
    salesPayment(salesPayment) {},
  },
};
</script>
